<template>
	<div class="room-card">
		<h4 class="room-card__title">
			{{ content.title }}

			<template v-if="roomType === 2">
				<span class="room-card__title--price">{{ content.price }} р.</span>

        <template v-if="$store.getters.isAuth">
          <button
            v-if="reserves[content.id]"
            type="button"
            @click="showModalDescriptionUser(reserves[content.id].user_id)"
            class="btn room-beds__image-user"
          >
            <tk-image
              :src="reserves[content.id].avatar || '/images/default-avatar-alt.svg'"
            />
          </button>
          <label
            v-else-if="!isBooking"
            class="room-beds__checkbox drop-checkbox"
          >
            <input
              type="checkbox"
              v-model="roomId"
              :value="content.id"
              hidden
            />
            <span class="drop-checkbox__icon">
								<tk-svg type="checked" />
							</span>
          </label>
        </template>

<!--				<label v-if="!isBooking" class="drop-checkbox">-->
<!--					<input-->
<!--						type="checkbox"-->
<!--						@change="selectRoom"-->
<!--						:checked="+roomId === content.id"-->
<!--						hidden-->
<!--					/>-->
<!--					<span class="drop-checkbox__icon">-->
<!--						<tk-svg type="checked" />-->
<!--					</span>-->
<!--				</label>-->
			</template>
		</h4>

		<div class="room-card__beds room-beds">
			<ul class="room-beds__list">
				<li v-for="item in content.beds" :key="item.id" class="room-beds__item">
					<p class="room-beds__title">
						<span class="room-beds__icon-container">
							<img :src="getIcon(item.bed_type_id)" alt="icon" decoding="async" />
						</span>
						{{ sleepPlaces[item.bed_type_id]?.title }}
						<span v-if="roomType === 3 && !reserves[item.id]" class="room-beds__price">
							{{ item.price }} р.
						</span>
					</p>
					<template v-if="$store.getters.isAuth && roomType === 3">
						<button
							v-if="reserves[item.id]"
							type="button"
							@click="showModalDescriptionUser(reserves[item.id].user_id)"
							class="btn room-beds__image-user"
						>
							<tk-image
								:src="reserves[item.id].avatar || '/images/default-avatar-alt.svg'"
							/>
						</button>
						<label
							v-else-if="!isBooking"
							class="room-beds__checkbox drop-checkbox"
						>
							<input
								type="checkbox"
								v-model="checked"
								:value="item.id"
								hidden
							/>
							<span class="drop-checkbox__icon">
								<tk-svg type="checked" />
							</span>
						</label>
					</template>
				</li>
			</ul>
		</div>

		<div class="room-card__gallery room-gallery">
			<ul class="room-gallery__list">
				<li
					v-for="(itm, idx) in images.slice(0, 3)"
					:key="idx"
					@click="showGallery"
					class="room-gallery__item"
				>
					<tk-image :src="itm" width="43" height="47" :alt="itm.hash" />
				</li>
				<li
					v-if="images.slice(3).length"
          @click="showGallery"
					class="room-gallery__item room-gallery__item--count"
				>
					{{ images.slice(3).length }}
				</li>
			</ul>
		</div>

		<div class="room-card__description-container">
			<p class="room-card__description" :class="{ 'more-text': isMore }">
				{{ content.description }}
			</p>
			<button
				v-if="content.description.length > 130"
				@click="isMore = !isMore"
				type="button"
				class="btn room-card__more-desc"
			>
				читать далее
			</button>
		</div>
	</div>
</template>

<script>
import modals from '@/constants/modals'
import sleepPlaceIcons from '@/constants/sleepPlace'

export default {
	props: {
		roomType: {
			type: Number,
			default: null
		},
		isBooking: {
			type: Boolean,
			default: false
		},
		content: {
			type: Object,
			default: () => ({})
		},
		reserves: {
			type: Object,
			default: () => ({})
		},
		sleepPlaces: {
			type: Object,
			default: () => ({})
		}
	},
	watch: {
		checked(val) {
			this.$router.push({ query: { ...this.$route.query, bed_ids: [...val] } })
		},
    roomId(val) {
      this.$router.push({ query: { ...this.$route.query, room_id: [...val] } })
    },
		'$route.query.room_id'(val) {
      if (val) {
        this.roomId = [...val]
      } else {
        this.roomId = []
      }
		},
		'$route.query.bed_ids'(val) {
      if (val) {
        this.checked = [...val]
      } else {
        this.checked = []
      }
		}
	},
	data() {
		return {
			roomId: [],
			isMore: false,
			checked: []
		}
	},
	mounted() {
		const { bed_ids, room_id } = this.$route.query
		if (room_id) {
			this.roomId = typeof room_id === 'string' ? [room_id] : [...room_id]
		}

		if (bed_ids?.length) {
			this.checked = typeof bed_ids === 'string' ? [bed_ids] : [...bed_ids]
		}
	},
	computed: {
		images() {
      const images = []
      this.content.images.forEach((itm) => {
        const image = itm.image
        image.description = this.content.title
        images.push(image)
      })
      this.content.beds.forEach((bed) => {
        const image =  bed.images[0]?.image
        if (image) {
          image.description = this.content.title
          if (this.sleepPlaces[bed.bed_type_id]) {
            image.description += ' - ' + this.sleepPlaces[bed.bed_type_id]?.title
          }
          images.push(bed.images[0].image)
        }
      })
			return images
		}
	},
	methods: {
		getIcon(name) {
      if (!sleepPlaceIcons[name]?.icon) {
        return
      }

			return require(`@/assets/img/icons/sleep-place/${sleepPlaceIcons[name]?.icon}.svg`)
		},
		selectRoom(evt) {
			if (evt.target.checked) {
				this.$router.push({
					query: { ...this.$route.query, room_id: this.content.id }
				})
			} else {
				const { room_id, ...rest } = this.$route.query
				this.$router.push({
					query: { ...rest }
				})
			}
		},
		showModalDescriptionUser(payload) {
			if (!payload) {
				return
			}

			this.$store.commit('showModal', {
				name: modals.DESCRIPTION_USER,
				props: {
					id: payload
				}
			})
		},
		showGallery() {
			this.$store.commit('showModal', {
				name: modals.PLACE_CAROUSEL,
				props: this.images,
				isShow: true
			})
		}
	}
}
</script>

<style lang="sass">
.room-card
  width: 100%
  height: 100%
  max-width: 340px
  padding: 30px 20px
  background: #FFFFFF
  box-shadow: 0px 10px 18px rgba(95, 83, 80, 0.05)
  border-radius: 10px

  &__title
    display: flex
    align-items: center
    justify-content: space-between
    margin: 0
    padding: 0 12px
    margin-bottom: 20px
    font-weight: 600
    font-size: 16px
    line-height: 16px
    color: #3B3F47

    &--price
      display: inline-block
      margin-left: 10px
      margin-right: auto
      font-weight: 500
      font-size: 12px
      color: #1AC386

  &__description
    margin: 0
    max-height: 50px
    font-weight: 400
    font-size: 14px
    line-height: 17px
    color: #3B3F47
    overflow: hidden
    transition: max-height 0.3s ease-out

    &.more-text
      max-height: 500px
      transition: max-height 0.3s ease-out

  &__more-desc
    margin-top: 20px
    color: $green
    font-weight: 400
    font-size: 14px
    line-height: 17px

  .room-gallery
    margin-bottom: 20px

    &__list
      margin: 0
      display: flex
      gap: 5px

    &__item
      width: 43px
      height: 47px
      cursor: pointer

      &--count
        display: flex
        justify-content: center
        align-items: center
        border: 1px solid rgba(0, 0, 0, 0.1)
        border-radius: 4px

  .room-beds
    margin-bottom: 20px

    &__list
      margin: 0

    &__item
      display: flex
      align-items: center
      gap: 5px
      justify-content: space-between
      padding: 12px
      border-bottom: 1px solid rgba(0, 0, 0, 0.05)

    &__image-user
      position: relative
      width: 16px
      height: 16px
      flex-shrink: 0
      overflow: hidden
      border-radius: 50%
      cursor: pointer
      box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px
      img
        position: absolute
        border-radius: 50%
        width: 100%
        height: 100%
        top: 0
        left: 0
        object-fit: cover

    &__title
      margin: 0
      font-weight: 500
      font-size: 12px
      line-height: 16px
      color: #3B3F47

    &__icon-container
      display: inline-block
      width: 25px

      img
        vertical-align: middle

    &__price
      display: inline-block
      margin-left: 10px
      color: #1AC386
</style>
