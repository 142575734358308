<template>
	<div class="drop-down-list">
		<tk-drop-down is-disabled-outside-hide>
			<template #toggle="{ active }">
				<div class="drop-down-list__header" :class="{ active: active }">
					<label
						v-if="isRoom && roomType === 2 && !reserves[id]"
						@click.stop
						class="drop-down-list__checkbox drop-checkbox"
						:class="{ 'room-checkbox': isRoom }"
					>
						<input
							@change="change"
							:checked="isChecked"
							:disabled="isBooking"
							type="checkbox"
							hidden
						/>

						<span class="drop-checkbox__icon">
							<tk-svg type="checked" />
						</span>
					</label>

					<span class="drop-down-list__header-text">
						{{ title }}
					</span>

					<span
						class="drop-down-list__header-price"
						v-if="roomType === 2 && isRoom"
					>
						{{ parseInt(price) }} р.
					</span>

					<tk-svg class="icon-arrow" type="arrow-down" />
				</div>
			</template>

			<template #content>
				<ul class="drop-down-list__list">
					<template v-for="item in content" :key="item.id">
						<li
							class="drop-down-list__item"
							:class="{ disabled: room || isBooking }"
						>
							<div
								class="drop-down-list__content"
								:class="{ 'drop-down-list__content--room': isRoom }"
							>
								<span
									v-if="getIcon(item.bed_type_id)"
									class="drop-down-list__icon-container"
								>
									<img :src="getIcon(item.bed_type_id)" alt="icon" decoding="async" />
								</span>
								<span class="drop-down-list__title-value">
									{{ item.title }}
								</span>
								<span
									v-if="isAmenities || (roomType === 3 && !reserves[item.id])"
									class="drop-down-list__price-value"
								>
									+{{ parseInt(item.price)?.toLocaleString() }} р.
								</span>
							</div>

							<label
								v-if="isAmenities || (roomType === 3 && !reserves[item.id])"
								class="drop-down-list__checkbox drop-checkbox"
							>
								<input
									v-model="checked"
									:value="item.id"
									type="checkbox"
									:disabled="isBooking"
									hidden
								/>

								<span class="drop-checkbox__icon">
									<tk-svg type="checked" />
								</span>
							</label>
						</li>
					</template>
				</ul>
			</template>
		</tk-drop-down>
	</div>
</template>

<script>
import sleepPlaceIcons from '@/constants/sleepPlace'

export default {
	props: {
		roomType: {
			type: Number,
			default: null
		},
		title: {
			type: String,
			default: ''
		},
		isBooking: {
			type: Boolean,
			default: false
		},
		id: {
			type: [String, Number],
			default: null
		},
		price: {
			type: [Number, String],
			default: 0
		},
		isAmenities: {
			type: Boolean,
			default: false
		},
		content: {
			type: Object,
			default: () => ({})
		},
		defaultValue: {
			type: [Array, String],
			default: () => []
		},
		isChecked: {
			type: Boolean,
			default: false
		},
		reserves: {
			type: Object,
			default: () => ({})
		},
		isRoom: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		checked(val) {
			if (this.isRoom) {
				this.$router.push({
					query: { ...this.$route.query, bed_ids: [...val] }
				})
			} else {
				this.$router.push({
					query: { ...this.$route.query, amenities: [...val] }
				})
			}
		},
		defaultValue() {
			if (this.defaultValue && typeof this.defaultValue === 'string') {
				this.checked = [this.defaultValue]

				return
			}

			if (this.defaultValue?.length && Array.isArray(this.defaultValue)) {
				this.checked = [...this.defaultValue]

				return
			}

			this.checked = []
		}
	},
	data() {
		return {
			room: null,
			checked: []
		}
	},
	created() {
		if (this.defaultValue && typeof this.defaultValue === 'string') {
			this.checked = [this.defaultValue]
		}

		if (this.defaultValue?.length && Array.isArray(this.defaultValue)) {
			this.checked = [...this.defaultValue]
		}
	},
	methods: {
		getIcon(name) {
			if (!sleepPlaceIcons[name]?.icon) {
				return
			}

			return require(`@/assets/img/icons/sleep-place/${sleepPlaceIcons[name]?.icon}.svg`)
		},
		change(evt) {
			if (evt.target.checked) {
				// this.room = this.id
				this.$emit('selectRoom', { roomId: this.id, action: 'add' })
			} else {
				// this.room = null
				this.$emit('selectRoom', { roomId: this.id, action: 'remove' })
			}
		}
	}
}
</script>

<style lang="sass">
.drop-down-list
  padding: 25px 20px

  &__header
    display: flex
    justify-content: space-between
    align-items: center
    font-weight: 500
    font-size: 16px
    line-height: 18px
    color: #3B3F47

    span
      font-weight: 500
      font-size: 12px
      line-height: 16px

    .icon-arrow
      width: 13px
      height: 8px
      transition: all 0.3s linear

    &.active .icon-arrow
      transform: rotate(180deg)
      transition: all 0.3s linear

    .room-checkbox
      margin-right: 10px

  &__icon-container
    display: inline-block
    width: 25px

    img
      vertical-align: middle

  &__header-text
    font-size: 16px
    line-height: 18px
    color: #3B3F47

  &__header-price
    margin-left: 10px
    margin-right: auto
    color: $green

  &__list
    margin-top: 10px

  &__item
    display: flex
    justify-content: space-between
    align-items: center
    padding: 10px 3px
    border-bottom: 1px solid rgba(0, 0, 0, 0.05)

  &__content
    font-weight: 500
    font-size: 12px
    line-height: 16px

  &__title-value
    color: #3B3F47

  &__price-value
    margin-left: 10px
    color: $green
</style>

<style lang="sass">
.drop-checkbox
  display: block
  width: 16px
  height: 16px
  cursor: pointer

  &__icon
    display: flex
    justify-content: center
    align-items: center
    width: 16px
    height: 16px
    border-radius: 50%
    border: 1px solid #1AC386
    transition: all 0.3s linear

  input:disabled ~ span
    cursor: not-allowed

  input:checked ~ span
    background-color: $green
    transition: all 0.3s linear
</style>
