export default {
  1: {
    icon: 'one-bed'
  },
  2: {
    icon: 'two-bed'
  },
  3: {
    icon: 'two-sofa'
  },
  4: {
    icon: 'couch'
  },
  5: {
    icon: 'mattress'
  },
  6: {
    icon: 'two-mattress'
  },
  7: {
    icon: 'cot'
  }
}