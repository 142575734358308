<template>
	<div class="price-sidebar">
		<div class="price-sidebar__rating price-rating">
			<div v-if="roomType === 1" class="price">{{ price }} ₽/ночь</div>
			<div class="rating">
				<tk-svg type="star" /> {{ rating.toFixed(2) }} ({{ reviewsCount }} отзывов)
			</div>
		</div>

		<form action="" class="price-sidebar__form sidebar-form">
			<span class="arrival">Прибытие</span>
			<!-- v-if="!dates" -->
			<span class="departure">Выезд</span>
			<!-- v-if="!dates" -->
			<tk-datepicker
				v-model="values.dates"
				type="daterange"
				popper-class="date-time-picker"
				:disabled="isBooking"
				range-separator=""
				start-placeholder=""
				:teleported="false"
				:disabled-date="disabledDates"
				end-placeholder=""
				value-format="YYYY-MM-DD"
				format="DD.MM.YYYY"
				class="daterange-editor"
			/>

			<div
				class="guests"
				:class="{ active: guestsCountActive }"
				@click="guestsCountActive = !guestsCountActive"
			>
				<div class="title">Гостей</div>
				<div class="count">{{ lodgers }} гостей</div>
			</div>

			<transition>
				<ul class="quantity" v-if="guestsCountActive">
					<li class="quantity__item">
						<div class="text-wrapper">
							<p class="text">Взрослые</p>
							<p class="text_small">Возраст от 13</p>
						</div>
						<tk-input-number
							:disabled="isBooking"
							v-model="values.guests"
							:min="1"
							:max="10"
						/>
					</li>
					<li class="quantity__item">
						<div class="text-wrapper">
							<p class="text">Дети</p>
							<p class="text_small">Возраст 0-12</p>
						</div>
						<tk-input-number
							:disabled="isBooking"
							v-model="values.children"
							:min="0"
							:max="10"
						/>
					</li>
				</ul>
			</transition>
		</form>

		<template v-if="total">
			<ul class="total__list">
				<li v-if="roomType === 1" class="total__item total__item_price">
					<span class="service">
						{{ price }}₽ х {{ total.nights.count }} ночей
					</span>
					<span class="price">{{ total.result.total }} ₽</span>
				</li>
				<li v-if="isShowPricePlace" class="total__item total__item_price">
					<span class="service">
						{{ total.nights.amount }}₽ х {{ total.nights.count }} ночей
					</span>
					<span class="price">{{ total.result.total }} ₽</span>
				</li>
				<li class="total__item total__item_fee">
					<span class="service">Сбор за услуги Теремка</span>
					<span class="price">{{ total.commission.total }} ₽</span>
				</li>
				<li class="total__item total__item_coupon">
					<span class="service">Купон</span>
					<span class="price coupon-discount">
						{{ total.result.discount }} ₽
					</span>
				</li>
			</ul>

			<template v-for="room in listRooms" :key="room.id">
				<DropDownList
					:title="room.title"
					:is-booking="isBooking"
					:price="room.price"
					:reserves="users"
					:room-type="roomType"
					:id="room.id"
					:is-checked="values?.room_id?.includes(room.id.toString())"
					:content="room.beds"
					:default-value="values.bed_ids"
					class="price-sidebar__additional"
					@update="
						(evt) => ((values.bed_ids = evt), (values.room_id = room.id))
					"
					@selectRoom="(evt) => (selectRoom(evt))"
					is-room
				/>
			</template>

			<DropDownList
				v-if="additionalAmenities?.length"
				title="Доп услуги"
				:is-booking="isBooking"
				is-amenities
				:room-type="roomType"
				:content="additionalAmenities"
				@update="(evt) => (values.amenities = evt)"
				:default-value="values.amenities"
				class="price-sidebar__additional"
			/>

			<div v-if="isShowPrice" class="total-amount">
				<div class="total-amount__sum">
					<span>Итого</span>
					<span>{{ total.result.total }} ₽</span>
				</div>
				<div class="total-amount__desc">
					Это конечная сумма. <br />Дополнительные сборы взиматься не будут.
				</div>
			</div>

			<tk-button
				v-if="!isBooking"
				class="price-sidebar-btn"
				:disabled="isDisabled"
				type="button"
				@click="submit"
			>
				Забронировать
			</tk-button>
		</template>
	</div>
</template>

<script>
import DropDownList from './views/place/components/DropDownList.vue'
import { arrToObj } from '@/utils/normalizeData'
import { nextTick } from "vue";
import room from "@/components/views/place/cards/Room";
import { isDateInBookingInterval } from "@/utils/dateTime";

// TODO: refactoring

export default {
	name: 'PriceSidebar',
	components: { DropDownList },
	props: {
		roomType: {
			type: Number,
			default: null
		},
		additionalAmenities: {
			type: Array,
			default: () => []
		},
		rooms: {
			type: Array,
			default: () => []
		},
		isBooking: {
			type: Boolean,
			default: false
		},
		price: {
			type: Number,
			default: 0
		},
		placeId: {
			type: [Number, String],
			default: 0
		},
		reserves: {
			type: Object,
			default: () => ({})
		},
		rating: {
			type: Number,
			default: 0
		},
		reviewsCount: {
			type: Number,
			default: 0
		},
		countSleepPlace: {
			type: Number,
			default: 0
		}
	},
	watch: {
		values: {
			handler: async function () {
				const { dates, room_id, ...rest } = this.values
				const query = { ...rest }

				if (dates?.length) {
					query.date_from = dates[0]
					query.date_to = dates[1]
				}

				if (room_id) {
					query.room_id = room_id
				}

        query.city = this.$route.query.city

				this.$router.push({ query: { ...query } })
			},
			deep: true
		},
		'$route.query': async function () {
			this.setValues()

			await this.calculate()
		}
	},
	data() {
		return {
			values: {
				dates: [
					this.$route.query.date_from || null,
					this.$route.query.date_to || null
				],
				children: +this.$route.query.children || 0,
				guests: +this.$route.query.guests || 0,
				bed_ids: this.$route.query.bed_ids
					? typeof this.$route.query.bed_ids === 'string'
						? [this.$route.query.bed_ids]
						: [...this.$route.query.bed_ids]
					: [],
        room_id: this.$route.query.room_id
          ? typeof this.$route.query.room_id === 'string'
            ? [this.$route.query.room_id]
            : [...this.$route.query.room_id]
          : [],
				amenities: this.$route.query.amenities
					? typeof this.$route.query.amenities === 'string'
						? [this.$route.query.amenities]
						: [...this.$route.query.amenities]
					: []
			},
      disabledDatesSet: new Set(),
			total: null,
			guestsCountActive: false,

      calculateAbortController: new AbortController(),
		}
	},
	async mounted() {
		if (this.placeId) {
			await this.calculate()

			const response = await this.$api.reserve.getReservesDatesPlace({
				place_id: this.placeId
			})

			if (response?.status) {
        this.disabledDatesSet = new Set();
				// [...response.response.reserve, ...response.response.user_reserve].forEach((interval) => {
        response.response.reserve.forEach((interval) => {
          const start = new Date(interval[0])
          start.setHours(0, 0, 0, 0)
          const end = new Date(interval[1])
          end.setHours(23, 59, 59, 999)
          while (start <= end) {
            this.disabledDatesSet.add(start.getTime())
            start.setDate(start.getDate() + 1)
          }
        })
			}
		}

		// rooms
		await this.$store.dispatch('getSleepPlaces')
	},
  beforeUnmount() {
    this.calculateAbortController.abort()
  },
  computed: {
		isShowPrice() {
			if (this.roomType === 1) {
				return true
			}

			if (this.roomType === 2 && this.values.room_id) {
				return true
			}

			if (this.roomType === 3 && this.values.bed_ids?.length) {
				return true
			}

			return false
		},
		isShowPricePlace() {
			if (this.roomType === 2 && this.values.room_id) {
				return true
			}

			if (this.roomType === 3 && this.values.bed_ids?.length) {
				return true
			}

			return false
		},
		users() {
			return arrToObj(this.reserves, this.roomType === 2 ? 'room_id' : 'bed_id')
		},
		sleepPlace() {
			return this.$store.state.sleepPlaces
		},
		listRooms() {
			return this.rooms.map((itm) => ({
				...itm,
				beds: itm.beds.map((item) => ({
					...item,
					title: this.sleepPlace[item.bed_type_id]?.title
				}))
			}))
		},
		lodgers() {
			return this.values.guests + this.values.children
		},
		isDisabled() {
			if (!this.lodgers) {
				return true
			}

			if (!this.values.dates) {
				return true
			}

			if (this.roomType === 2 && !this.values.room_id?.length) {
				return true
			}

			if (this.roomType === 3 && !this.values.bed_ids?.length) {
				return true
			}

      if (this.total.booking_check_errors && !Array.isArray(this.total.booking_check_errors)) {
        return true
      }

			return false
		}
	},
	methods: {
    selectRoom(evt) {
      if (evt.action === 'add') {
        this.$router.push({
          query: { ...this.$route.query, room_id: [...this.values.room_id, +evt.roomId] }
        })
      } else {
        let { room_id, ...rest } = this.$route.query
        let rooms = []
        if (Array.isArray(room_id)) {
          const idx = room_id.findIndex((rid) => rid.toString() === evt.roomId.toString())
          rooms = [...room_id.slice(idx+1), ...room_id.slice(0, idx)]
        }
        this.$router.push({
          query: { ...rest, room_id: rooms }
        })
      }
    },
		setValues() {
			this.values = {
				dates: [
					this.$route.query.date_from || null,
					this.$route.query.date_to || null
				],
				children: +this.$route.query.children || 0,
				guests: +this.$route.query.guests || 0,
				bed_ids: this.$route.query.bed_ids
					? typeof this.$route.query.bed_ids === 'string'
						? [this.$route.query.bed_ids]
						: [...this.$route.query.bed_ids]
					: [],
				room_id: this.$route.query.room_id
          ? typeof this.$route.query.room_id === 'string'
            ? [this.$route.query.room_id]
            : [...this.$route.query.room_id]
          : [],
				amenities: this.$route.query.amenities
					? typeof this.$route.query.amenities === 'string'
						? [this.$route.query.amenities]
						: [...this.$route.query.amenities]
					: []
			}
		},
		disabledDates(payload) {
      const currentDate = payload.setHours(0, 0, 0, 0)
      return this.disabledDatesSet.has(currentDate) || !isDateInBookingInterval(currentDate)
		},
		async calculate() {
      this.calculateAbortController.abort()
      this.calculateAbortController = new AbortController()

			const { dates, ...rest } = this.values

			if (dates?.length) {
				rest.date_from = dates[0]
				rest.date_to = dates[1]
			}

			const result = await this.$api.place.calculate({
				...rest,
				place_id: this.placeId
			}, { controller: this.calculateAbortController })

			if (result && result.status) {
        const total = result.response
				this.total = total
        if (typeof total.booking_check_errors === 'object' &&
            total.booking_check_errors !== null &&
            !Array.isArray(total.booking_check_errors))
        {
          this.$store.commit('showNotification', {
            type: 'error',
            text: total.booking_check_errors?.reserve ? total.booking_check_errors?.reserve[0] : total.booking_check_errors?.guests[0]
          })
        }
			}
		},
		async submit() {
      const { bed_ids, amenities, room_id, ...payload } = this.$route.query

      payload.bed_ids = bed_ids
        ? typeof bed_ids === 'string'
          ? [bed_ids]
          : [...bed_ids]
        : []

      payload.amenities = amenities
        ? typeof amenities === 'string'
          ? [amenities]
          : [...amenities]
        : []

      if (room_id) {
        payload.room_id = room_id ? (Array.isArray(room_id) ? room_id.join(',') : room_id) : ''
      }

			this.$router.push({
				name: 'BookingRequest',
				query: {
					...this.$route.query
				}
			})
		}
	}
}
</script>

<style lang="sass">
.el-range-editor.is-disabled
  background-color: transparent !important

  input
    background-color: transparent !important

.el-date-table td.disabled .el-date-table-cell
  //background-color: red !important
  //color: white

  //&__text
    //text-decoration: line-through

  //&__text:before
  //  border-bottom: 1px solid red
  //  position: absolute
  //  content: ""
  //  left: 1px
  //  width: 65%
  //  height: 50%
  //  transform: rotate(-30deg)
</style>

<style lang="sass">
.price-sidebar
  width: 100%
  margin-bottom: 30px
  max-width: 312px

  box-shadow: 0 9px 36px -7px rgba(0, 0, 0, 0.15)
  border-radius: 12px
  background-color: white

  &__additional
    box-shadow: 0px 0.5px 0px #CBCBCB

    .dropdown-content
      position: static

  .price-rating
    padding: 20px
    display: flex
    justify-content: space-between
    align-items: center
    height: 64px

    .price
      font-size: 18px
      line-height: 18px
      font-weight: bold

    .rating
      display: flex
      align-items: center
      font-size: 14px
      line-height: 24px
      svg
        margin-right: 5px

  .sidebar-form
    position: relative

    .arrival, .departure
      position: absolute
      top: 15px

      font-weight: bold
      font-size: 16px
      line-height: 18px
      color: #3B3F47

      z-index: 2

    .arrival
      left: 7%

    .departure
      left: 56%

    .daterange-editor
      position: relative

      height: 68px !important

      border-top: 1px solid #e2e2e2 !important

      &::after
        content: ''

        position: absolute
        top: 0
        bottom: 0
        left: 0
        right: 0

        width: 1px
        height: 100%
        margin: auto

        background-color: #e2e2e2

      .el-icon-date,
      .el-range__close-icon
        display: none

      .el-range-input
        width: 49%
        padding-right: 45px
        padding-top: 20px

    .el-date-editor
      padding: 0
      width: 100%
      height: 68px
      justify-content: space-between
      border-radius: unset
      border-right: unset
      border-left: unset
      .el-range-input
        cursor: pointer
        width: 50%
        height: 40px
        text-align: left
				// padding-left: 20px
				// padding-top: 20px
        padding: 35px 20px 15px 20px
        &:nth-child(2)
          border-right: 1px solid #e2e2e2
      .el-range__icon
        display: none
      .el-range__close-icon
        display: none
      .el-range-separator
        display: none

    .guests
      position: relative
      padding: 15px 20px
      height: 68px
      border-bottom: 1px solid #e2e2e2
      border-top: 1px solid #e2e2e2
      cursor: pointer
      &:before
        content: url("~@/assets/images/svg/arrow-down.svg")
        position: absolute
        right: 20px
        top: 25px
        transition: 0.2s linear
      &.active
        &:before
          transform: rotate(180deg)
      .title
        margin-bottom: 4px

        font-weight: bold
        font-size: 16px
        line-height: 18px
        color: #3B3F47

    .quantity
      margin: 0
      padding: 20px
      &__item
        display: flex
        justify-content: space-between
        align-items: center

        &:not(:last-child)
          margin-bottom: 20px

      .text
        margin-top: 0
        font-size: 16px
        line-height: 16px
        margin-bottom: 4px
        &_small
          margin-top: 0
          margin-bottom: 0
          font-weight: 300
          font-size: 12px
          line-height: 12px
          color: #AFA9A7
          letter-spacing: -0.01em
      ::v-deep(.el-input-number)
        width: 95px
        &__decrease,
        &__increase
          border: 1px solid #5F5350
          border-radius: 50%
          width: 29px
          height: 29px
          top: -4px
          &.is-disabled
            border: 1px solid #E5E5E5
        .el-icon-plus,
        .el-icon-minus
          position: absolute
          top: 7px
          right: 7px
        .el-input
          padding-top: 1px
          display: flex
          justify-content: center
          align-items: center
          input.el-input__inner
            background: transparent
            border: none
            padding: 0

  .price-sidebar-btn
    display: flex
    justify-content: center
    align-items: center
    margin: 20px auto
    padding: 15px 40px

    .v-enter-active, .v-leave-active
      transition: opacity .1s
    .v-enter, .v-leave-to
      opacity: 0

  .total__list
    padding: 25px 20px
    list-style: none
    box-shadow: 0px 0.5px 0px #CBCBCB

  .total__item
    display: flex
    justify-content: space-between
    align-items: center
    font-weight: 500
    font-size: 14px
    line-height: 18px

    &:not(:last-child)
      margin-bottom: 15px

  .service
    text-decoration: underline

  .coupon-discount
    font-weight: bold
    color: #1AC386

  .total-amount
    padding: 20px
    padding-top: 30px
    font-weight: bold
    font-size: 18px
    line-height: 18px

    &__sum
      display: flex
      justify-content: space-between
      gap: 20px
      align-items: center
      margin-bottom: 10px

    &__desc
      font-size: 14px
      line-height: 16px
      font-weight: 400

      color: #DDC9C1
</style>
